body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    font-family: Arial, sans-serif;
}

body {
    background-color: #f0f4f8;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 80%;
}

#login-container input {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: 2px solid #4a90e2;
    border-radius: 8px;
    box-sizing: border-box;
}

#login-button {
    background-color: #4a90e2;
    color: #fff;
    border: none;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

#login-button:hover {
    background-color: #357abd;
}

#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px 0;
    padding-bottom: 20px;
    width: 100%;
    max-width: 480px;
    overflow-y: auto;
    height: 100%;
    position: relative;
    transition: transform 0.3s ease;
}

.refresh-indicator {
    display: none;
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    position: absolute;
    width: 100%;
    top: -50px;
    transition: transform 0.3s ease;
}

.refresh-indicator.visible {
    display: block;
}

#cagnotte-container {
    text-align: center;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

#profiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 12px;
    padding: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    max-width: 360px;
}

.profile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 360px;
    text-align: center;
}

.profile img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0;
}

.profile-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.button-container button {
    background-color: #ff6f61;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
}

.button-container button:disabled {
    background-color: lightgray;
    cursor: not-allowed;
    touch-action: none;
}

.profile-details {
    display: flex;
    flex-direction: column;
}

.profile-details .name {
    font-size: 16px;
    margin-top: 5px;
}

.counter {
    font-size: 21px;
    font-weight: bold;
}

.positive {
    color: rgb(78, 189, 78);
}

.negative {
    color: #e94e77;
}

.period-buttons {
    text-align: center;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 90%;
}

.period-buttons button {
    background-color: #4a90e2;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 12px;
}

.period-buttons button.active {
    background-color: #357abd;
}

.bot-btn {
    color: black;
    border: none;
    padding: 12px;
    cursor: pointer;
    width: 50%;
    font-size: 14px;
    background: none;
}

button {
    touch-action: manipulation;
}

button:active {
    transform: scale(0.98);
}

.row {
    display: flex;
    justify-content: center;
}

#bonus {
    justify-self: flex-end;
    color: rgb(78, 189, 78);
}

#bonus span {
    color: black;
}